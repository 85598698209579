.historyError-filters > div {
  margin-bottom: 1rem;
}

@media (max-width: 420px){
  .margin-filter{
    margin-left: 5px !important;
  }
  .margin-button{
    margin-right: 45px !important;
  }

}
