.container {
  align-content: center;
}

.form-signin {
  padding-top: 30px;
}

.form-signin input[type='email'] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0.35rem;
  border-bottom-left-radius: 0.35rem;
}
