.body-login {
  align-content: center;
  margin-left: 30rem;
  margin-top: 7rem;
}


@media (max-width: 420px){
  .margin-import{
    margin: 0 !important;
  }
  .title{
    text-align: center !important;
  }

}
