.orderWeight-filters > div {
  margin-bottom: 1rem;
}


@media (max-width: 380px){
  .margin-filter{
    margin-left: 1px !important;
  }
  .margin-button{
    margin-right: 45px !important;
  }

}