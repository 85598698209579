.pull-right {
  float: right !important;
}

.some-top-air {
  margin-top: 12px;
}


@media (max-width: 420px){
  .margin-import{
    margin: 0 !important;
  }
  .title{
    text-align: center !important;
  }

}


.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

[hidden] {
  display: none !important;
}

.some-air {
  margin-top: 12px;
  margin-left: 20px;
}

.input-number {
  text-align: right;
}

.left-padding-switch {
  padding-left: 2.5rem;
}

.export-button {
  margin-top: 12px;
  margin-left: 20px;
  margin-right: 20px;
}
