.text-picking {
  color: #285494;
  margin: 2rem 0 1rem;
  font-size: 100%;
  font-weight: 500;
}

.text-position-title {
  color: #4fc2d0;
  margin-top: 15px;
}

.text-picking-titles {
  color: black;
  font-size: 150%;
  font-weight: 500;
  margin: 2rem 0 1rem;
}
