span.nav-link {
  cursor: pointer;
}

.clickable:hover {
  color: #36b9cc;
}

.logo-Navbar {
  height: 65px;
  /* width: 14%; */
}
