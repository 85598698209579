.request-filters > div {
  margin-bottom: 1rem;
}


@media (max-width: 380px){
  .center{
    margin-left: 2px !important;
  }
  .margin-import{
    margin: 0 !important;
  }

}