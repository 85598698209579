html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

#root {
  height: 100%;
}

.navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
}

.navbar-brand img {
  width: 45px;
}

.user-dropdown {
  width: 25px;
}

.form-signin {
  -webkit-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
  background: #fff;
  padding: 0 50px 50px;
  max-width: 450px;
  position: relative;
  -webkit-box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  text-align: center;
  padding-top: 0;
}

.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}

.form-signin input[type='email'] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type='password'] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.logo-login {
  margin: 0 20px;
  padding: 10px;
}

/* Simple CSS3 Fade-in-down Animation */
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

#wrap,
.wrapper {
  min-height: 100%;
}

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 20px;
}

/* Simple CSS3 Fade-in Animation */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  opacity: 0;
  -webkit-animation: fadeIn ease-in 1;
  -moz-animation: fadeIn ease-in 1;
  animation: fadeIn ease-in 1;

  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
}

.fadeIn.first {
  -webkit-animation-delay: 0.4s;
  -moz-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.fadeIn.second {
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.fadeIn.third {
  -webkit-animation-delay: 0.8s;
  -moz-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.fadeIn.fourth {
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  animation-delay: 1s;
}

/* Simple CSS3 Fade-in Animation */
.underlineHover:after {
  display: block;
  left: 0;
  bottom: -10px;
  width: 0;
  height: 2px;
  background-color: #56baed;
  content: '';
  transition: width 0.2s;
}

.underlineHover:hover {
  color: #0d0d0d;
}

.underlineHover:hover:after {
  width: 100%;
}

.logo {
  max-width: 80%;
  width: 300px;
}

.footer {
  background-color: #f8f9fa;
}

.footer:before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background: #ccc;
  top: -30px;
  left: 0;
}

.text-red {
  color: #d20606;
}

.footer > div {
  padding: 1rem 0;
}

.text-vhumanize {
  color: #4e73df;
}

.btn-vhumanize {
  color: white;
}

.background-oceandrive {
  background-color: #008ab0;
  color: #ffffff;
}

h1 > span,
h2 > span {
  color: #36b9cc;
  text-decoration: underline;
  font-weight: 500;
}

.text-newskin,
.form-group label,
h3,
h4,
h5 {
  color: #4e73df;
}

.text-newskin > i,
.form-group label > i,
h1 > i,
h2 > i,
h3 > i,
h4 > i,
h5 > i {
  color: #ccc;
  -webkit-text-fill-color: #ccc;
}

.text-position-title {
  color: #4fc2d0;
}

@media (max-width: 576px) {
  .form-signin {
    padding: 0 20px 50px;
  }

  .wrapper {
    padding-left: 0;
    padding-right: 0;
  }
}

.pdf-frame {
  display: none;
}
