.pull-right {
  float: right !important;
}

.some-top-air {
  margin-top: 12px;
}

[hidden] {
  display: none !important;
}

.some-air {
  margin-top: 12px;
  margin-left: 20px;
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@media (max-width: 420px){
  .margin-import{
    margin: 0 !important;
  }
  .title{
    text-align: center !important;
  }
  .margin-button{
    margin-right: 45px !important;
  }

}