.button-submit {
  min-width: 150px;
  margin-bottom: 2rem;
}

.width-observations {
  width: 100%;
  height: 100px;
  resize: none;
}
.button-delete-modal {
  margin: 1rem;
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.creation-info {
  border-radius: 0.5rem;
  border: solid #155282 1px;
}

.subtitle-section,
.request-title-container {
  margin-bottom: 10px;
  display: flex;
}
.request-title-container h1,
.request-title-container h2,
.request-title-container h3,
.request-title-container h4,
.request-title-container h5 {
  margin: 0;
}
.text-position-title {
  color: #4fc2d0;
}
.request-buttons {
  display: flex;
  justify-content: flex-end;
  flex: 2;
}

.mt-45 {
  margin-top: 2rem !important;
}
